<template>
  <div class="tab">
    <template v-if="$store.getters.options">
      <form>
        <!-- <reglage-champ tag="checkbox" label="Fermer les commentaires" cle="commentaires-fermes" hint="Les commentaires seront encore visibles" /> -->
        <reglage-champ tag="checkbox" label="Les nouvelles inscriptions doivent être validées à la main" cle="valider-inscriptions"/>
        <reglage-champ tag="checkbox" label="Afficher une annonce sur tous les commentaires" cle="afficher-annonce-globale"/>
        <reglage-champ tag="textarea" label="Annonce affichée sur tous les commentaires" cle="annonce-globale">
        </reglage-champ>

        <reglage-champ tag="textarea" label="Termes interdits" cle="termes-interdits">
          Un terme par ligne. La recherche se fait sur un mot complet uniquement, sauf dans le cas des urls. Préfixer le mot d'une étoile * pour ignorer les accents.
        </reglage-champ>
        <reglage-champ tag="textarea" label="Domaines d'email interdits" cle="emails-interdits">
          Un domaine par ligne. Ces domaines s'ajoutent à <a href="https://raw.githubusercontent.com/disposable-email-domains/disposable-email-domains/master/disposable_email_blocklist.conf" target="_blank">ceux listés sur cette page</a>
        </reglage-champ>
        <reglage-champ tag="textarea" label="Tags préférés" cle="tags-preferes">
          Un terme par ligne. Liste des tags les plus utilisés pour l'administration des membres
        </reglage-champ>
        <!-- <reglage-champ tag="textarea" label="Ip bannies" cle="ip-bannies" hint="Une ip par ligne" /> -->
      </form>
    </template>
    <chargement v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("chargerOptions",{vue:this});
  },
};
</script>

<style>
</style>