<template>
  <div class="tab">
    <p>
      <a @click="chargerInscriptions">Rafraîchir</a>
      &middot;
      <a @click="changeTab('admin')">Préférences d'inscription</a>
    </p>

    <template v-if="inscriptions">
      <div v-for="member in inscriptions" :key="member.id">
        <member-admin-card :member="member" masquer="true" />
        <br />
      </div>
    </template>
    <chargement v-else />
  </div>
</template>

<script>
import MemberAdminCard from "@/components/Members/MemberAdminCard.vue";
export default {
  components: { MemberAdminCard },
  data() {
    return {
      inscriptions: false,
    };
  },
  mounted() {
    this.chargerInscriptions();
  },
  methods: {
    chargerInscriptions() {
      api
        .get("members/inscriptions", {
          params: { page: 1 },
        })
        .then((response) => {
          this.inscriptions = response.data;
        });
    },
  },
};
</script>

<style>
</style>