<template>
  <div class="tab">
    <div class="columns">
      <div class="column title">Informations</div>
      <div class="column">
        <div class="control">
          <p>
            <b>Création</b>
            {{dateHeureFr(story.createdAt)}}
          </p>
          <p>
            <b>Mise à jour</b>
            {{dateHeureFr(story.updatedAt)}}
          </p>
          <p>
            <b>Id</b>
            {{story.id}} /
            <b>storyId</b>
            {{story.storyId}}
          </p>
          <p>
            <b>URL</b>&nbsp;
            <a target="_blank" :href="story.url">{{story.title}}</a>
          </p>
          <p>
            <b>{{story.comments}}</b> commentaire(s) sur
            <b>{{story.pages}}</b> page(s)
          </p>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column title">Annonce</div>
      <div class="column">
        <div class="control">
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="meta.afficherAnnonce"
              @change="sauver('afficherAnnonce', true)"
            />
            Afficher une annonce sur cette story
          </label>
          <div v-if="meta.afficherAnnonce" class="field">
            <div class="control">
              <label class="label">Message de l'annonce</label>
              <input
                type="text"
                class="input"
                v-model="meta.messageAnnonce"
                @change="sauver('messageAnnonce', true)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>    
    <div class="columns">
      <div class="column title">Modération</div>
      <div class="column">
        <div class="control">
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="meta.onlyVip"
              @change="sauver('onlyVip', true)"
            />
            Conversation réservée aux VIP
          </label>
        </div>

        <div class="control">
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="meta.moderationAPriori"
              @change="sauver('moderationAPriori', true)"
            />
            Les messages doivent être approuvés manuellement pour être affichés
          </label>
        </div>

        <div class="control">
          <label class="checkbox">
            <input type="checkbox" v-model="meta.fermer" @change="sauver('fermer', true)" />
            Fermer cette discussion
          </label>
          <div v-if="meta.fermer" class="field">
            <div class="control">
              <label class="label">Message de fermeture</label>
              <input
                type="text"
                class="input"
                v-model="meta.messageFermeture"
                @change="sauver('messageFermeture', true)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column title">Recharger</div>
      <div class="column">
        <div class="control">
          <button class="button is-link is-small" @click="reCharger">Recharger la conversation</button>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column title">Import</div>
      <div class="column">
        <div class="control">
          <button class="button is-warning is-small" @click="reImporter">Ré-importer la conversation</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      meta: {
        fermer: false,
        onlyVip:false,
        messageFermeture: "",
        afficherAnnonce:false,
        messageAnnonce:""
      },
    };
  },
  mounted() {
    this.meta.fermer = this.story.meta && this.story.meta.fermer;
    this.meta.messageFermeture = this.story.meta.messageFermeture;
    this.meta.onlyVip = this.objVal(this.story.meta,'onlyVip');
    this.meta.afficherAnnonce = this.objVal(this.story.meta,'afficherAnnonce');
    this.meta.messageAnnonce = this.objVal(this.story.meta,'messageAnnonce')
  },
  methods: {
    reCharger() {
        api.post("story",{storyId : this.story.storyId, now:true}).then((response) => {
          alert('Story rechargée')
//          this.$bus.$emit("load-story");
        });
    },
    reImporter() {
      if (
        confirm(
          "Tous les nouveaux commentaires seront perdus. Voulez-vous réimporter la conversation ?"
        )
      ) {
        api.get("story/" + this.story.storyId + "?import").then((response) => {
          // this.setStory(response.data);
          this.$bus.$emit("load-story");
        });
      }
    },
    sauver(cle, reloadStory = false) {
      api
        .post(`story/${this.story.storyId}/meta`, {
          cle: cle,
          valeur: this.meta[cle],
        })
        .then((response) => {
          if (reloadStory) {
            this.setStory(response.data);
          }
        });
    },
  },
};
</script>

<style>
</style>