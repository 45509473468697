<template>
  <div class="answers">
    <div v-if="folded" class="folded">
      Il y a {{pluriel(compterReponses(comment.answers),'réponse')}} à ce message.
      <a
        @click="doFoldComment"
      >Afficher la conversation</a>
    </div>
    <Comment
      v-else
      v-for="answer in answers"
      :key="'answers-'+answer.id"
      :comment="answer"
      :niveau="niveau"
      child="true"
    />
  </div>
</template>

<script>
// import Comment from "@/components/Comments/Comment.vue";
export default {
  props: ["folded", "comment", "niveau"],
  data() {
    return {
      answers: [],
    };
  },
  components: {
    Comment: () => import("@/components/Comments/Comment.vue"),
  },
  mounted() {
    this.answers = this.comment.answers;
    this.on("add-comment-" + this.comment.id, (comment) => {
      console.log("add-comment-" + this.comment.id);
      if (!this.answers.some((tmpComment) => tmpComment.id == comment.id)) {
        console.log("add-comment OK");
        this.answers.push(comment);
      }
    });
  },
  methods: {
    compterReponses(comments) {
      let nb = comments ? comments.length : 0;
      comments.forEach((comment) => {
        nb += this.compterReponses(comment.answers);
      });
      return nb;
    },
    isFolded() {
      let folds = localStorage.getItem("folds");
    },
    doFoldComment() {
      this.$bus.$emit("fold-comment-" + this.comment.id);
    },
  },
};
</script>

<style>
.folded {
  text-align: right;
  padding-right: 1.25em;
  padding-top: 0.25em;
}
</style>