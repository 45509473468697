<template>
  <span class="tag" v-if="visible">
    <a @click="$bus.$emit('rechercher-membre','#'+tag.tag)">{{tag.tag}}</a>
    <button class="delete is-small" @click="effacer"></button>
  </span>
</template>

<script>
export default {
  props: ["tag", "member"],
  data() {
      return {
          visible:true
      }
  },
  methods: {
    effacer() {
      api
        .delete(`member/${this.member.id}/tag/${this.tag.id}`)
        .then((response) => {
          this.visible=false
        });
    },
  },
};
</script>

<style scoped lang="scss">
.tag {
  button {
    opacity: 0.5;
  }
  &:hover {
    button {
      opacity: 1;
    }
  }
}
</style>