<template>
  <div>
    <template v-if="isReduced">
      <p v-if="loggedMember">Connecté avec le compte {{$store.state.member.name}}</p>
    </template>
    <template v-else>
      <div class="message is-danger is-small" v-if="message">
        <div class="message-body" v-html="message"></div>
      </div>
      <article v-else class="message is-small">
        <div class="message-body">
          <template v-if="loggedMember()">
            <span class="title is-5">{{$store.state.member.name}}</span> 
            <!-- &middot;
            <span class="subtitle is-6">{{$store.state.member.email}}</span>-->
            <p>
              Vous êtes connecté.
              <a @click="logOut">Cliquez ici pour vous déconnecter</a>.
              Avant de poster des commentaires, nous vous invitons à consulter
              <a
                @click="rules"
              >la charte de la communauté</a>.
            </p>
          </template>
          <template v-else>
            Vous n'êtes pas identifié.
            <a
              @click="triggerEvent('logIn')"
            >Cliquez ici pour vous connecter</a> et participer aux discussions. Si vous n'avez pas encore de compte
            <a
              @click="triggerEvent('signUp')"
            >cliquez ici pour créer votre compte membre</a>.
          </template>
        </div>
      </article>
    </template>
    <br />
  </div>
</template>

<script>
export default {
  name: "MemberRecap",
  props: [],
  data() {
    return {
      message: "",
    };
  },
  mounted() {
    this.$bus.$on("member-message", (message) => {
      this.message = message;
    });
  },
  methods: {
    logOut() {
      this.$store.commit("logOut");
      this.evenement("logOut");
    },
    rules() {
      this.evenement("rulesPage");
    },
  },
};
</script>

<style lang="scss">
</style>
