<template>
  <li :class="classes">
    <a @click="checkAndChangeTab" :aria-label="tooltip" data-balloon-pos="right">
      <span class="icon is-small" :data-amount="dataAmount">
        <i class="fas" :class="'fa-'+icone" aria-hidden="true"></i>
      </span>
      <!-- <span class="ml-2" :class="hiddenOnMobile">{{title}}</span> -->
    </a>
  </li>
</template>

<script>
export default {
  props: ["nom", "title", "infos", "icone", "amount", "admin"],
  methods: {
    checkAndChangeTab() {
      if(this.admin) {
        this.$store.dispatch("verifAdmin", {
          callback: () => {
            this.changeTab(this.nom)
          },
        });
      }else {
        this.changeTab(this.nom)
      }
    },
  },
  computed: {
    dataAmount() {
      let a = Number(this.amount);
      if (a > 0) {
        if (a > 9) {
          return "●";
        } else {
          return a;
        }
      }
    },
    tooltip() {
      return this.infos + (this.amount ? " (" + this.amount + ")" : "");
    },
    classes() {
      let classes = [];
      classes.push(this.isTabActive(this.nom));
      if (this.amount) {
        classes.push("with-amount");
      }
      return classes.join(" ");
    },
  },
};
</script>

<style scoped lang="scss">
.icon {
  margin: 0 !important;
  &[data-amount] {
    position: relative;
    &:after {
      content: attr(data-amount);
      position: absolute;
      width: 16px;
      height: 16px;
      background: red;
      text-align: center;
      line-height: 16px;
      font-size: 11px;
      color: white;
      border-radius: 50%;
      top: 0;
      right: 0;
      transform: translateX(50%) translateY(-40%);
    }
  }
}
.amount {
  font-size: smaller;
}
.with-amount a {
  // color:#ff2f00;
}
</style>