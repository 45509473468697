<template>
  <div>
    <div class="tags mt-4">
      <template v-for="tag in tags">
        <member-tag :key="tag.slug" :tag="tag" :member="member" />
      </template>
      <button
        class="button is-text is-small"
        @click="afficherForm=true"
        v-if="!afficherForm"
      >Ajouter un tag</button>
    </div>
    <form @submit.prevent="addTag()" v-if="afficherForm">
      <div class="field is-small has-addons">
        <div class="control is-expanded">
          <input class="input is-small" type="text" v-model="tag" placeholder="Ajouter un tag" />
          <p class="help tags-preferes" v-if="tagsPreferes.length">
            Tags fréquents:
            <a v-for="tag in tagsPreferes" :key="tag" @click="addTag(tag)">{{tag}}</a>
          </p>
        </div>
        <div class="control">
          <button class="button is-small is-warning">Ajouter</button>
          <button class="button is-small is-text" @click="afficherForm=false">Fermer</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import MemberTag from "@/components/Members/MemberTag.vue";
export default {
  components: { MemberTag },
  props: ["member"],
  data() {
    return {
      afficherForm: false,
      tag: "",
      tags: [],
    };
  },
  mounted() {
    this.tags = this.member.tags;
  },
  computed: {
    tagsPreferes() {
        return this.$store.getters.tagsPreferes.filter(element => {
            for(let key in this.tags) {
                let tag = this.tags[key];
                if(element == tag.slug || element == tag.tag) {
                    return false;
                }
            }
            return true;
        });
    },
  },
  methods: {
    addTag(tag = false) {
      if (!tag) {
        tag = this.tag;
      }
      api.post(`member/${this.member.id}/tags`, { tags:tag }).then((response) => {
        this.tags = response.data.tags;
        this.tag = "";
      });
    },
  },
};
</script>

