<template>
  <span class="level-item admin-actions" v-if="isAdmin">
    <template v-if="comment.hidden == 1 && story.meta.moderationAPriori">
      <span class="level-item">
        <input type="checkbox" @change="selectionner" />
      </span>
      <a @click="hide" class="level-item" aria-label="Approuver ce message" data-balloon-pos="up">
        <span class="icon is-small" :class="comment.hidden ? 'has-text-success' : ''">
          <i class="fas fa-check"></i>
        </span>
      </a>
    </template>
    <a
      v-if="!afficherActions"
      @click="verifierAdminEtAfficherActions"
      class="level-item"
      aria-label="Afficher les outils d'administration"
      data-balloon-pos="up"
    >
      <span class="icon is-small">
        <i class="fas fa-ellipsis-h"></i>
      </span>
    </a>

    <template v-else>
      <a
        @click="embed"
        class="level-item"
        aria-label="Intégrer ce commentaire"
        data-balloon-pos="up"
      >
        <span class="icon is-small">
          <i class="fas fa-share-square"></i>
        </span>
      </a>
      <a @click="pin" class="level-item" aria-label="Epingler ce commentaire" data-balloon-pos="up">
        <span class="icon is-small" :class="comment.pinned ? 'has-text-success rotate-45' : ''">
          <i class="fas fa-thumbtack"></i>
        </span>
      </a>
      <a
        @click="hide"
        class="level-item"
        aria-label="Masquer / Réafficher ce message"
        data-balloon-pos="up"
      >
        <span class="icon is-small" :class="comment.hidden ? 'has-text-danger' : ''">
          <i class="fas fa-eye"></i>
        </span>
      </a>
      <a @click="effacer" class="level-item" aria-label="Effacer ce message" data-balloon-pos="up">
        <span class="icon is-small">
          <i class="fas fa-trash"></i>
        </span>
      </a>
      <a @click="userAdmin" class="level-item" aria-label="Administration" data-balloon-pos="up">
        <span class="icon is-small">
          <i class="fas fa-user"></i>
        </span>
      </a>
      <a @click="userIp" class="level-item" aria-label="Adresse IP" data-balloon-pos="up">
        <span class="icon-text">
          <span class="icon">
            <i class="fas fa-globe"></i>
          </span>
          <span class="texte">{{comment.ip}}</span>
        </span>
      </a>
      <span class="level-item" aria-label="Rating" data-balloon-pos="up">
        <span class="icon-text">
          <span class="icon">
            <i class="fas fa-ranking-star"></i>
          </span>
          <span class="texte"> {{comment.memberRating}}</span>
        </span>
      </span>
    </template>
  </span>
</template>

<script>
export default {
  props: ["comment"],
  data() {
    return {
      afficherActions: false,
      hidden: false,
    };
  },
  mounted() {
    this.hidden = this.comment.hidden;
    this.$bus.$on("hide-comment-" + this.comment.id, this.hide);
  },
  methods: {
    verifierAdminEtAfficherActions() {
      this.$store.dispatch("verifAdmin", {
        callback: () => {
          this.afficherActions = true;
        },
      });
    },
    selectionner() {
      this.$bus.$emit("selection-commentaires", this.comment.id);
    },
    embed() {
      let embed = `<embed data-comm-id="${this.comment.id}" data-story-id="${this.comment.storyId}"></embed>`;
      prompt("Copier/coller ce code pour intégrer le commentaire", embed);
    },
    userIp() {
      let query = this.$route.query;
      query.ip = this.comment.ip;
      let params = this.$route.params;
      params.tab = "ip";
      this.$router.push({ name: "StoryTab", params, query });
    },
    userAdmin() {
      let query = this.$route.query;
      query.memberId = this.comment.memberId;

      let params = this.$route.params;
      params.tab = "members";
      // console.log(query)
      this.$router.push({ name: "StoryTab", params, query });
    },
    effacer() {
      if (confirm("Effacer ce message ? Cette action est irreversible")) {
        api
          .delete(`story/${this.comment.storyId}/comment/${this.comment.id}`)
          .then((response) => {
            if (this.notifAdmin()) {
              this.sendNotificationForComment(
                "admin-comment-efface",
                this.comment
              );
            }

            this.$bus.$emit("commentaire-efface-" + this.comment.id);
          });
      }
    },
    hide() {
      api
        .patch(`story/${this.comment.storyId}/comment/${this.comment.id}/hide`)
        .then((response) => {
          console.log(response.data);
          if (this.notifAdmin(!this.hidden)) {
            this.sendNotificationForComment(
              "admin-comment-masque",
              this.comment
            );
          }
          this.hidden = !this.hidden;

          this.$bus.$emit("commentaire-hidden-" + this.comment.id);
          /*          this.$bus.$emit("update-comment-fields", {
            id: response.data.id,
            hidden: response.data.hidden == 1,
          });
          */
        });
    },
    pin() {
      api
        .patch(`story/${this.comment.storyId}/comment/${this.comment.id}/pin`)
        .then((response) => {
          /*this.$bus.$emit("update-comment-fields", {
            id: response.data.id,
            pinned: response.data.pinned == 1,
          });*/
          this.$bus.$emit("load-all-comments");
        });
    },
  },
};
</script>

<style>
</style>