<template>
  <div class="masque" v-if="!story.meta.moderationAPriori">
    <div class="box mt-2 mb-2 commentaire-masque">
      <template v-if="this.comment.memberHidden == '1'">
        <icone
          nom="red-card"
          ensemble="fak"
        >Tous les commentaires de {{comment.member}} sont indisponibles</icone>
      </template>
      <template v-else>
        <icone nom="referee" ensemble="fak">Ce commentaire de {{comment.member}} est indisponible</icone>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["comment"],
};
</script>

<style>
.commentaire-masque {
  font-size: small;
  padding: 1em;
}
</style>