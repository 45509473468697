<template>
  <div class="tab" v-if="isAdmin">
    <form @submit.prevent="rechercher">
      <label class="label">Rechercher un membre</label>
      <div class="field has-addons">
        <div class="control is-expanded">
          <input class="input" type="text" placeholder="Nom ou email" v-model="recherche" />
          <p class="help tags-preferes" v-if="$store.getters.tagsPreferes.length">
            Tags fréquents:
            <a v-for="tag in $store.getters.tagsPreferes" :key="tag" @click="$bus.$emit('rechercher-membre','#'+tag)">{{tag}}</a>
          </p>
        </div>
        <div class="control">
          <button class="button is-info" :class="{'is-loading':isLoading}">Rechercher</button>
        </div>
      </div>
    </form>
    <div class="mt-5" v-if="members.length">
      <div v-for="resultat in members">
        <member-admin-card :member="resultat" />
        <br />
      </div>
      <center v-if="restePages">
        <br />
        <button @click="nextPage" class="button is-link with-hint">
          Charger plus de membres
          <span>Page {{pageMember+1}}</span>
        </button>
      </center>
    </div>
  </div>
</template>

<script>
import MemberAdminCard from "@/components/Members/MemberAdminCard.vue";
export default {
  components: { MemberAdminCard },
  data() {
    return {
      pageMember: 1,
      recherche: "",
      members: [],
      si: false,
    };
  },
  mounted() {
    if (this.$route.query.memberId) {
      this.recherche = this.$route.query.memberId;
    } else if (this.$route.query.s) {
      this.recherche = this.$route.query.s;
    }
    this.$bus.on('rechercher-membre',s => {
      this.recherche=s;
      this.rechercher();
    })
    
    setTimeout(() => {
      this.rechercher();
    }, 500);
  },
  computed: {
    restePages() {
      return this.members.length % 10 == 0;
    },
  },
  methods: {
    nextPage() {
      this.pageMember++;
      this.rechercher(false);
    },
    rechercher(vider = true) {
  
      this.startLoading();
      if (vider) {
        this.members = [];
      }
      clearTimeout(this.si);
      this.si = setTimeout(() => {
        api
          .get("members", {
            params: { search: this.recherche, page: this.pageMember },
          })
          .then((response) => {
            this.members = this.members.concat(response.data);
          })
          .finally(() => {
            this.endLoading();
          });
      }, 500);
    },
  },
};
</script>

<style>
.tags-preferes a:not(:first-of-type):before {
  content: ", ";
}
</style>