<template>
  <div id="comments">
    <post />
    <Pinned :pinned="pinned" v-if="comments.length>0" />

    <comments-actions />

    <template v-for="comment in comments">
      <div :key="'comment-'+comment.id">
        <comment :comment="comment" parent="true" niveau="1" />
        <hr v-if="!affichageChronologique" />
      </div>
    </template>
  </div>
</template>
<script>
import Post from "@/components/Comments/Actions/Post.vue";
import Pinned from "@/components/Comments/Pinned.vue";
import Comment from "@/components/Comments/Comment.vue";
import CommentsActions from "@/components/Comments/CommentsActions.vue";
export default {
  name: "Comments",
  components: { Pinned, Comment, CommentsActions, Post },
  props: ["comments", "pinned"],
  data() {
    return {
      commentsSelection: [],
    };
  },
  mounted() {
    this.commentsSelection = [];

    /**
     * Boucle sur la sélection de commentaires pour les valider uns par uns
     */
    this.$bus.$on("valider-messages-selectiones", () => {
      this.commentsSelection.forEach((commId) => {
        this.$bus.$emit("hide-comment-" + commId);
      });
      this.commentsSelection = [];
    });

    /**
     * Ajouter ou enlever des commentaires de la selection
     */
    this.$bus.$on("selection-commentaires", (commId) => {
      if (this.commentsSelection.includes(commId)) {
        const index = this.commentsSelection.indexOf(commId);
        if (index > -1) {
          this.commentsSelection.splice(index, 1);
        }
      } else {
        this.commentsSelection.push(commId);
      }
      if (this.commentsSelection.length) {
        this.$bus.$emit("activer-validation-commentaires-selectionnes");
      } else {
        this.$bus.$emit("desactiver-validation-commentaires-selectionnes");
      }
      console.log(this.commentsSelection);
    });
  },
};
</script>
<style>
</style>
